<template>
  <button class="buttonXS" :class="color" @click="click">{{ btnTxt }}</button>
</template>

<script>
export default {
  name: "btnXS",
  props: {
    btnTxt: String,
    color: String
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.buttonXS {
  width: 64px;
  height: 28px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @extend .f_caption;
}
.primary {
  background: variables.$primary;
  color: #fff;
}
.neutral {
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #cbcbcb;
  color: variables.$txt-default;
}
.negative {
  background: variables.$negative;
  color: #fff;
}
.positive1 {
  background: #063d7c;
  color: #fff;
}
.positive2 {
  background: #5c86b5;
  color: #fff;
}
.back {
  background: variables.$bg-gray-3;
  color: #fff;
}
</style>
