<template>
  <div>
    <Header />
    <pageTitle txt="過去の請求" :backBtn="backBtn" backURL="/iseya/home/" />
    <div id="meta">
      <div class="meta-date">{{ today | dateFormatYM }}</div>
      <div class="meta-month">
        <input type="text" v-model="year" class="meta-year__input" />
        年
        <input type="text" v-model="month" class="meta-month__input" />
        月
        <div>
          <btnXS btnTxt="表示" color="neutral" @click="display" />
        </div>
      </div>
      <div class="meta-btn">
        <div class="meta-btn__prev" @click="prevMonth">前の月</div>
        <div>
          <btnS btnTxt="今月" color="neutral" @click="todayMonth" />
        </div>
        <div class="meta-btn__next" @click="nextMonth">次の月</div>
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">店舗名</div>
          <div class="list-head__item col-data">10%税込</div>
          <div class="list-head__item col-data">軽8%税込</div>
          <div class="list-head__item col-data">軽8%値引き</div>
          <div class="list-head__item col-data">合計</div>
          <div class="list-head__item col-btn"></div>
        </li>
        <li class="list-item whole">
          <div class="list-item__data col-name">
            全店分（アスハレコーポレーション宛）
          </div>
          <div class="list-item__data col-data">
            {{ total.eight_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ total.ten_price | comma }}
          </div>
          <div class="list-item__data col-data negative">
            {{ total.service_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{
              (total.eight_price + total.ten_price + total.service_price)
                | comma
            }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/invoice/detail/',
                query: {
                  shopID: 0,
                  name: '全店分（アスハレコーポレーション宛）',
                  date: ymdFormat(today)
                }
              }"
            >
              <btnS
                class="home-btns__item"
                btnTxt="詳細を見る"
                color="positive1"
              />
            </router-link>
          </div>
        </li>
        <li class="list-item" v-for="shop in list" :key="shop.id">
          <div class="list-item__data col-name">
            {{ shop.name }}
          </div>
          <div class="list-item__data col-data">
            {{ shop.eight_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ shop.ten_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ shop.service_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{
              (shop.eight_price + shop.ten_price + shop.service_price) | comma
            }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/invoice/detail/',
                query: {
                  shopID: shop.id,
                  name: shop.name,
                  date: ymdFormat(today)
                }
              }"
            >
              <btnS
                class="home-btns__item"
                btnTxt="詳細を見る"
                color="positive1"
              />
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 60px;
}

.meta-month {
  display: flex;
  align-items: center;
  @extend .f_body;
  .buttonXS {
    margin-left: 10px;
  }
}

.meta-year__input,
.meta-month__input {
  box-sizing: border-box;
  border: solid 1px variables.$bg-gray-3;
  border-radius: 3px;
  background: #fff;
  height: 28px;
  padding: 0 12px;
  text-align: right;
  margin-right: 3px;
}

.meta-year__input {
  width: 64px;
}

.meta-month__input {
  width: 46px;
  margin-left: 10px;
}

.meta-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  .buttonS {
    margin: 0 30px;
  }
}

.meta-btn__prev,
.meta-btn__next {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.meta-btn__prev {
  &:before {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../assets/icon/arrow-left-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-right: 15px;
  }
}

.meta-btn__next {
  &:after {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../assets/icon/arrow-right-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-left: 15px;
  }
}

.print-btn {
  margin-left: auto;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 980px;
  margin: 0 auto 20px;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list-subhead {
  display: flex;
  height: 25px;
  font-size: 1.2rem;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;

  .list-item__data {
    justify-content: center;
  }
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
  &.total {
    &:nth-of-type(even) {
      background: none;
    }
  }
  &.whole {
    position: relative;
    &:before {
      content: "";
      width: 100vw;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      right: calc(50% - 50vw);
      bottom: 0;
      transform: translate(0, 0);
      @media screen and (max-width: 1280px) {
        right: -150px;
      }
    }
    &:after {
      content: "";
      width: 100vw;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      right: calc(50% - 50vw);
      bottom: -2px;
      transform: translate(0, 0);
      @media screen and (max-width: 1280px) {
        right: -150px;
      }
    }
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.col-data {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
}

.col-name {
  width: 304px;
}

.col-data {
  width: 130px;
}

.col-btn {
  width: 155px;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnXS from "@/components/btn/btnXS";
import btnS from "@/components/btn/btnS";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnXS,
    btnS
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      today: "",
      year: 0,
      month: 0,
      list: [],
      total: {}
    };
  },
  created() {
    if (this.$route.query.date) {
      const wk = this.$route.query.date.split("/");
      this.today = new Date(wk[0], wk[1] - 1, wk[2]);
      this._setYM();
    } else {
      this.todayMonth();
    }
  },
  methods: {
    prevMonth() {
      this.today.setMonth(this.today.getMonth() - 1);
      this._setYM();
    },
    todayMonth() {
      this.today = new Date();
      this.today = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
      this._setYM();
    },
    nextMonth() {
      this.today.setMonth(this.today.getMonth() + 1);
      this._setYM();
    },
    display() {
      this.today = new Date(this.year, parseInt(this.month, 10) - 1, 1);
      if (Number.isNaN(this.today.getDate())) {
        this.today = new Date();
      }
      this._setYM();
    },
    async _setYM() {
      this.year = this.today.getFullYear();
      this.month = this.today.getMonth() + 1;

      const api = new ApiClient();
      const param = {
        start_date: this.ymdFormat(this.today)
      };
      const result = await api.post("/iseya/invoice/list", param);
      this.list = result.list;
      this.total = result.total;
    }
  }
};
</script>
